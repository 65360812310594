import Vue from "vue";
import App from "./App.vue";
import "vue-search-select/dist/VueSearchSelect.css";
import titleMixin from "./common/titleMixin";
import router from "./router";
import "vue-search-select/dist/VueSearchSelect.css";

Vue.config.productionTip = false;

Vue.mixin(titleMixin);

new Vue({
    router: router,
    render: h => h(App)
}).$mount("#app");
