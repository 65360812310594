import * as Constants from "./constants";

export class StorageHelper {
    static setUser(user) {
        localStorage.setItem(Constants.LS_USER_KEY, JSON.stringify(user));
    }

    static getUser() {
        let user = localStorage.getItem(Constants.LS_USER_KEY);
        try {
            return JSON.parse(user);
        } catch (e) {
            console.error("error parsing user");
        }
    }

    static removeUser() {
        localStorage.removeItem(Constants.LS_USER_KEY);
    }

    static setToken(token) {
        localStorage.setItem(Constants.LS_TOKEN_KEY, token);
    }

    static getToken() {
        return localStorage.getItem(Constants.LS_TOKEN_KEY);
    }

    static removeToken() {
        return localStorage.removeItem(Constants.LS_TOKEN_KEY);
    }
}
