import Vue from "vue";
import VueRouter from "vue-router";
import { StorageHelper } from "../common/storageHelper";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login.vue")
    },
    {
        path: "/home",
        name: "home",
        alias: "/",
        meta: { requiresAuth: true },
        component: () => import("../views/ResearchProject.vue")
    },
    {
        path: "/project-research/:research_project_id",
        name: "research_project_detail",
        meta: { requiresAuth: true },
        component: () => import("../views/ResearchProjectDetail.vue")
    },
    {
        path: "/project-research/:research_project_id/patients",
        name: "patients",
        meta: { requiresAuth: true },
        component: () => import("../views/Patients.vue")
    },
    {
        path: "/project-research/:research_project_id/category/:category_id/entries",
        name: "entries",
        meta: { requiresAuth: true },
        component: () => import("../views/Entries.vue")
    },
    {
        path: "/project-research/:research_project_id/dashboard",
        name: "dashboard",
        meta: { requiresAuth: true },
        component: () => import("../views/Dashboard.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active", // active class for *exact* links.
    routes
});

router.beforeEach((to, from, next) => {
    const authUser = StorageHelper.getUser();
    const token = StorageHelper.getToken();
    if (to.meta.requiresAuth) {
        if (authUser && token) {
            if (to.path === "/") {
                next({name : "home"})
            } else {
                next();
            }
        } else {
            next({ name: "login" });
        }
    } else {
        if (to.name === "login" && authUser && token) {
            next({name : "home"})
        } else {
            next();
        }
    }
});

export default router;