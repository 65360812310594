export const BASE_URL_API = process.env.VUE_APP_BASE_URL_API;
export const PREFIX_KEY = "rc-research";


export const URL_API_USER = "users/";
export const URL_API_RESEARCH_PROJECT = "research-project/";
export const URL_API_HOSPITALS = "hospitals/";
export const URL_API_USER_LOGIN = URL_API_USER + "auth_token/";
export const URL_API_USER_ME = URL_API_USER + "me/";

export const URL_API_RELATIVE_PATIENTS = "/patients";
export const URL_API_RELATIVE_FORM_FIELDS = "/form-fields";
export const URL_API_RELATIVE_CATEGORY = "/category";
export const URL_API_RELATIVE_ENTRIES = "/entries";
export const URL_API_RELATIVE_DASHBOARD = "/dashboard";
export const URL_API_RELATIVE_EXPORT = "/export";

// Local Storage KEY
export const LS_TOKEN_KEY = PREFIX_KEY + "token_user";
export const LS_USER_KEY = PREFIX_KEY + "user_json";


export const LIMIT_RECORDS_API = 20;
export const TIMEZONE_DEFAULT = 'Europe/Rome';


export const CATEGORY_ENTRY = {
    MEDICAL_HISTORY: 1,
    ABLATION_PROCEDURE: 2,
    FOLLOW_UP: 3,
};

export const TYPE_FORM_FIELD = {
    LABEL: 1,
    FIELD: 2
};

export const TYPE_RESPONSE_FORM_FIELD = {
    BOOLEAN: 1,
    NUMERIC: 2,
    STRING: 3,
    RANGE: 4,
    DATETIME: 5,
    DATE: 6,
    DURATION: 7,
    SINGLE_CHOICE: 8,
    MULTIPLE_CHOICE: 9,
    SINGLE_CHOICE_BOOLEAN: 10,
    MULTIPLE_CHOICE_BOOLEAN: 11,
    CHOICE: 12,
};

