<template>
    <div :id="this.$route.name">
        <router-view />
    </div>
</template>

<style></style>
<script>
    export default {};
</script>
